import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubmitResult = ({ icon, click }) => {
  return (
    <div className="submit">
      <button type="button" onClick={(e) => click(e)}>
        <span>
          <FontAwesomeIcon icon={icon} size="lg" />
        </span>
      </button>
    </div>
  );
};

export default SubmitResult;
