import { useState, useRef } from "react";
import { API_URL } from "./utils";

import Input from "./shared/Input";
import Checkbox from "./shared/Checkbox";
import Submit from "./shared/Submit";
import SubmitWorking from "./shared/SubmitWorking";
import SubmitResult from "./shared/SubmitResult";

import {
  faSpinnerThird,
  faCheck,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";

function Newsletter() {
  const [names, setNames] = useState({
    champ: true,
    paint: false,
  });
  const [result, setResult] = useState("");

  const formElement = useRef();

  const handleUpdate = (name, value) => {
    if (name === "champ" || name === "paint") {
      const newValue = !value;

      setNames((names) => ({ ...names, [name]: newValue }));
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResult("working");

    const url = new URL(`${API_URL}/?f=newsletter`);
    const formData = new FormData(formElement.current);
    formData.append("champ", names.champ ? "1" : "0");
    formData.append("paint", names.paint ? "1" : "0");

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        formElement.current.reset();
        setNames({
          champ: true,
          paint: false,
        });
        setResult("success");
      } else {
        setResult("error");
      }
    } catch (error) {
      setResult("error");
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    formElement.current.reset();
    setNames({
      champ: true,
      paint: false,
    });
    setResult("");
  };

  return (
    <form
      method="post"
      action="/"
      onSubmit={(e) => handleSubmit(e)}
      ref={formElement}
    >
      <div className="newsletterFields">
        <Input
          type="text"
          label=""
          req={true}
          name="name"
          value=""
          errors={[]}
          placeholder="Name"
          autocomplete="name"
        />
        <Input
          type="text"
          label=""
          req={true}
          name="email"
          value=""
          errors={[]}
          placeholder="Email Address"
          autocomplete="email"
        />
        <div className="newsletterSubmit hideOnMobile">
          {result === "working" ? (
            <SubmitWorking icon={faSpinnerThird} spin={true} disabled={true} />
          ) : result === "success" || result === "error" ? (
            <SubmitResult
              icon={result === "success" ? faCheck : faTimes}
              click={handleReset}
            />
          ) : (
            <Submit name="Submit" icon={null} />
          )}
        </div>
      </div>

      <div className="newsletterCheckboxes">
        <Checkbox
          label="Valspar Championship"
          name="champ"
          checked={names.champ}
          click={handleUpdate}
        />
        <Checkbox
          label="Valspar Paint"
          name="paint"
          checked={names.paint}
          click={handleUpdate}
        />
      </div>

      <div
        className="newsletterSubmit showOnMobile"
        style={{ marginTop: "7.5px" }}
      >
        {result === "working" ? (
          <SubmitWorking icon={faSpinnerThird} spin={true} disabled={true} />
        ) : result === "success" || result === "error" ? (
          <SubmitResult
            icon={result === "success" ? faCheck : faTimes}
            click={handleReset}
          />
        ) : (
          <Submit name="Submit" icon={null} />
        )}
      </div>
    </form>
  );
}

export default Newsletter;
