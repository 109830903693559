import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SubmitWorking = ({ icon, spin, disabled }) => {
  return (
    <div className="submit">
      <button type="submit" disabled={disabled}>
        <span>
          <FontAwesomeIcon icon={icon} size="lg" spin={spin} />
        </span>
      </button>
    </div>
  );
};

export default SubmitWorking;
